<template>
    <b-card v-if="data" class="card-transaction" no-body>
        <b-card-header>
            <b-card-title>Your Colleagues</b-card-title>
        </b-card-header>

        <b-card-body>
            <div v-for="employee in data" :key="employee.uid" class="transaction-item align-items-start">
                <b-media no-body class="w-100">
                    <b-media-aside>
                        <b-avatar square size="42" :src="employee.photo" class="text-secondary" variant="transparent"
                            :text="avatarText(employee.name)" style="border-radius: 16px !important;">
                        </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                        <h6 class="transaction-title mb-0">
                            {{ employee.name }}
                        </h6>
                        <small>{{ employee.designation }}</small>
                    </b-media-body>
                </b-media>
                <!-- <div class="justify-content-start w-25"
        >
         <span class="text-nowrap text-body-heading  mr-1"
            >₹{{ parseFloat(employee.ctc_upper).toFixed(1) }} LPA</span
          >
        </div> -->
            </div>

        </b-card-body>
        <b-card-footer class="py-0">
            <div class="apply-job-package bg-light-primary rounded">
                <div>
                    <span class="d-inline mr-25 text-body font-small-3">
                        Invite colleagues to collaborate
                    </span>
                </div>
                <b-badge variant="light-primary" pill class="cursor-pointer" v-clipboard:copy="message"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError">
                    Invite Now
                </b-badge>
            </div>
        </b-card-footer>
    </b-card>
</template>

<script>
import {
    BCard, BCardHeader, BCardTitle, BCardBody, BBadge, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BCardFooter
} from 'bootstrap-vue'
import firebase from "firebase/app";
import "firebase/firestore"
import { avatarText } from "@core/utils/filter";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BMediaBody,
        BMedia,
        BMediaAside,
        BAvatar,
        BDropdown,
        BDropdownItem,
        BBadge,
        BCardFooter,
        ToastificationContent
    },
    // props: {
    //   data: {
    //     type: Array,
    //     default: () => [],
    //   },
    // },
    data() {
        return {
            data: [],
            message:`Hey there, collaborate with me in hiring from ${this.$store.state.mainUserCodes.CollegeName}. Visit ${window.location.href} now`
        }
    },
    methods: {
        onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Invite copied',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },
        getRecEmployee() {
            var collegecode = this.$store.state.mainUserCodes.CollegeCode;
            var recruiterCode = this.$store.state.mainUserCodes.RecruiterCode;
            let userId = firebase.auth().currentUser.uid
            if (recruiterCode == null) {
                firebase.firestore().collection("RecruiterId").doc(userId).get().then((doc) => {
                    recruiterCode = doc.data().RecruiterCode ?? null
                }).then(() => {
                    firebase.firestore().collection("RecruiterId").where("RecruiterCode", "==", recruiterCode)
                        .where("fromCollege", "array-contains", collegecode).limit(5).get().then((docs) => {
                            this.data = []
                            docs.forEach((stud) => {
                                var uid = stud.id;
                                if (uid != userId) {
                                    var obj = {}
                                    obj.id = uid
                                    obj.photo = stud.data().profilephoto;
                                    obj.designation = stud.data().Designation;
                                    obj.email = stud.data().email;
                                    obj.name = stud.data().ContactName;
                                    this.data.push(obj)
                                }
                            })
                        })
                })
            } else {
                firebase.firestore().collection("RecruiterId").where("RecruiterCode", "==", recruiterCode)
                    .where("fromCollege", "array-contains", collegecode).limit(5).get().then((docs) => {
                        this.data = []
                        docs.forEach((stud) => {
                            var uid = stud.id;
                            if (uid != userId) {
                                var obj = {}
                                obj.id = uid
                                obj.photo = stud.data().profilephoto;
                                obj.designation = stud.data().Designation;
                                obj.email = stud.data().email;
                                obj.name = stud.data().ContactName;
                                this.data.push(obj)
                            }
                        })
                    })
            }


        },
        avatarText
    },
    created() {
        if (this.$store.state.mainUserCodes) {
            this.getRecEmployee()

        }


        //changing when store value changed
        this.$store.watch(
            (state) => {
                return this.$store.state.mainUserCodes;
            },
            (newVal, oldVal) => {
                this.getRecEmployee()
            },
            { deep: true }
        );
    }
}
</script>
<style lang="scss">
.card .card-footer {
    border: none;
}

.apply-job-package {
    padding: 1.2rem;
    margin-bottom: 1.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    sup {
        top: -0.8rem;
    }
}
</style>
