<template>
  <b-row class="match-height">
    <b-col v-for="item in data" :key="item.icon" xl="6" sm="6">
      <statistic-card-vertical :icon="item.icon" :statistic="item.title" :statistic-title="item.subtitle"
        :color="item.color" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
