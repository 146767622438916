<template>
  <b-card
    
    no-body
    class="card-developer-meetup"
  >
  
    <div class="bg-light-primary rounded-top text-center meetup-img-wrapper">
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
      <b-button v-if="data != null" variant="primary" size="sm" class="mt-1" @click="isEventHandlerSidebarActive = true">
                <span class="font-small-3">Add Event</span>
      </b-button>
    </div>
     
    <b-card-body v-if="data != null">
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-start mb-1" v-if="data.startDate">
        <div class="meetup-day">
          <h6 class="mb-0">
            {{ weekday[new Date(data.startDate).getDay()] }}
          </h6>
          <h3 class="mb-0">
            {{ new Date(data.startDate).getDate() }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ data.eventTitle }}
          </b-card-title>
          <b-card-text class="mb-0">
            {{ setDescription(data.eventDesc) }}
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <b-media
        v-for="media in data.mediaData"
        :key="media.avatar"
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            square
            variant="light-primary"
            size="34" style="border-radius: 12px !important;"
          >
            <feather-icon
              :icon="media.avatar"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ media.title }}
          </h6>
          <small class="mt-0">{{ media.subtitle }}</small>
        </b-media-body>
      </b-media>

      <!-- avatar group -->
      <b-avatar-group
        class="mt-2 pt-50"
        size="34px" v-if="data.avatars && data.avatars.length > 0"
      >
        <b-avatar
          v-for="avatar in data.avatars"
          :key="avatar.avatar"
          v-b-tooltip.hover.bottom="avatar.fullName"
          :src="avatar.avatar"
          class="pull-up"
        />
        <h6 class="align-self-center cursor-pointer ml-1 mb-0">
          +42
        </h6>
      </b-avatar-group>

    </b-card-body>
    <b-card-body v-else>
      <div class="w-100 h-100 d-flex flex-column justify-content-center text-center align-items-center">
        <u-icon icon="fi-rr-calendar" class="text-secondary" size="40" style="opacity: 0.2; color:#043c57"/>
        <span style="opacity: 0.2;color:#043c57">No Upcoming Events</span>
        <b-button variant="primary" class="mt-2" @click="isEventHandlerSidebarActive = true">
                <span>Add Event</span>
      </b-button>
      </div>
      
    </b-card-body>
    <!-- <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar d-none"
            /> -->
    <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BButton, BCardText, BCardTitle, BMedia, BMediaAside, BCardHeader, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
} from 'bootstrap-vue'
import CalendarEventHandler from '../../apps/calendar/calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from '../../apps/calendar/useCalendar'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from '../../apps/calendar/calendarStoreModule'
import FullCalendar from '@fullcalendar/vue'
import firebase from "firebase/app";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BButton,
    BCardHeader,
    CalendarEventHandler,
    FullCalendar
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return{
    weekday : ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
      data: null

    }
  },
  methods:{
    setDescription(str){
      if(str && str.length > 100){
        var newStr = str.slice(0,100) + ' ...'
        return newStr
      }else return str
    }
  },
  // props: {
  //   data: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  
  created(){
    var uid = firebase.auth().currentUser.uid
    if(uid){
          var startfulldate = firebase.firestore.Timestamp.fromDate(new Date());
      var ref = firebase.firestore().collection("RecruiterId").doc(uid).collection("CalendarEvents")
      ref.where("startDate", ">=", startfulldate).limit(1).onSnapshot((snap) => {
        if(snap.size > 0){
        snap.docs.forEach((doc) => {
          // this.data = cal.data()
          if(doc.exists){
          var startTime = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
          }).format(new Date(doc.data().startDate.toDate()));
          var endTime = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
          }).format(new Date(doc.data().endDate.toDate()));
          this.data = {
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate.toDate(),
            eventTitle: doc.data().title,
            eventDesc: doc.data().extendedProps.description,
            mediaData: [
              {
                avatar: "CalendarIcon",
                title: new Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  weekday: "short",
                }).format(new Date(doc.data().startDate.toDate())),
                subtitle: startTime + " to " + endTime,
              },
              {
                avatar: "MapPinIcon",
                title: doc.data().extendedProps.location,
                subtitle: doc.data().eventUrl,
              },
            ],
            avatars: [],
          };
          console.log(this.data)
          }else{
            console.log('no document')
          }
        })
        }else{
          this.data = null
        }
      });

    }
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      refCalendar, 
      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    // fetchEvents()
  
    return {
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent, fetchEvents,
      removeEvent,
      refetchEvents,
      calendarOptions,
      refCalendar,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>
