<template>
  <b-card v-if="tableData" no-body class="card-company-table">
    <b-table :items="tableData" responsive :fields="fields" class="mb-0">
      <!-- company -->
      <template #cell(JobTitle)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            square
              :src="data.item.logo"
              size="42"
              class="text-secondary"
              variant="transparent"
              :text="avatarText(data.item.CompanyName)"
              style="border-radius: 16px !important;"
          ></b-avatar>
          <div class="ml-1">
            <span class=" text-nowrap" style="font-weight:600;color:#043c57; ">
              {{ data.item.JobTitle }}
            </span>
            <div class="font-small-2 font-weight-light text-monospace">
              ₹ {{ parseFloat(data.item.ctc_lower).toFixed(1) }} -
            {{ parseFloat(data.item.ctc_upper).toFixed(1) }} LPA
            </div>
          </div>
        </div>
      </template>
      <!-- views -->
       <template #cell(category)="data">
        <!-- <div class="d-flex flex-column"> -->
          <span class="font-weight-normal  text-nowrap"
            >{{ data.item.category }}
          </span>
          <!-- <span class="font-small-2 text-muted text-nowrap">{{ data.item.viewsub }}</span> -->
        <!-- </div> -->
      </template>
      

      <!-- revenue -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant(data.item.Status)}`"
          class="text-capitalize font-weight-bold align-self-center"
        >
          {{ data.item.Status }}
        </b-badge>
      </template>
      <template #cell(TotalApplied)="data">
        <span class="text-nowrap text-center">{{data.item.TotalApplied}}</span>
      </template>

      
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable, BAvatar, BImg, BBadge } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: "JobTitle", label: "JOB TITLE" },
        { key: "Status", label: "STATUS" },
        { key: "TotalApplied", label: "TOTAL APPLICATIONS" },
      ],
    };
  },
  setup() {
    const resolveUserStatusVariant = (status) => {
      if (status === "Screening") return "warning";
      if (status === "Active") return "success";
      if (status === "Closed") return "danger";
      return "primary";
    };

    const methodGenerateLocation = (location) => {
      if (location) {
        var locationStr = "";
        location.forEach((val) => {
          var valueList = val.split(",");
          if (locationStr.length > 0) {
            locationStr = locationStr + "," + " " + valueList[0];
          } else {
            locationStr = valueList[0];
          }
        });
        if (locationStr.length > 25) {
          return locationStr.substring(0, 15) + "...more";
        } else {
          return locationStr;
        }
      } else return "-";
    };
    return {
      resolveUserStatusVariant,
      methodGenerateLocation,
      avatarText
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.card-company-table ::v-deep td:nth-child(3) {
    text-align: center;
  }

.card-company-table ::v-deep th:nth-child(3) {
    text-align: center;
  }
  
</style>
