<template>
    <section id="dashboard-ecommerce">
        <b-row class="match-height">
            <b-col xl="4" md="6">
                <recruiter-medal :data="data.congratulations" />
            </b-col>
            <b-col xl="8" md="6">
                <recruiter-job-table :table-data="firebaseData.companyTable" />

            </b-col>
        </b-row>

        <b-row class="match-height">
            <!-- Company Table Card -->
            <b-col lg="4" md="6">
                <recruiter-statistics :data="firebaseData.statisticsItems" />
            </b-col>
            <!--/ Company Table Card -->
            <b-col lg="4" md="6">
                <recruiter-events />
            </b-col>
            <!-- Developer Meetup Card -->
            <b-col lg="4" md="6">
                <recruiter-colleagues  />
            </b-col>
            <!--/ Developer Meetup Card -->


        </b-row>
    </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import RecruiterMedal from "./RecruiterMedal.vue";
import RecruiterStatistics from "./RecruiterStatisticsCard.vue";
import EcommerceRevenueReport from "../ecommerce/EcommerceRevenueReport.vue";
import EcommerceOrderChart from "../ecommerce/EcommerceOrderChart.vue";
import EcommerceProfitChart from "../ecommerce/EcommerceProfitChart.vue";
import EcommerceEarningsChart from "../ecommerce/EcommerceEarningsChart.vue";
import RecruiterEvents from "./RecruiterEvents.vue";
import EcommerceBrowserStates from "../ecommerce/EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "../ecommerce/EcommerceGoalOverview.vue";
import EcommerceTransactions from "../ecommerce/EcommerceTransactions.vue";
import RecruiterJobTable from "./RecruiterJobTable.vue";
import RecruiterColleagues from "./RecruiterColleagues.vue";
export default {
    components: {
        BRow,
        BCol,

        RecruiterMedal,
        RecruiterJobTable,
        RecruiterStatistics,
        EcommerceRevenueReport,
        EcommerceOrderChart,
        EcommerceProfitChart,
        EcommerceEarningsChart,
        EcommerceBrowserStates,
        EcommerceGoalOverview,
        EcommerceTransactions,
        RecruiterColleagues,
        RecruiterEvents
    },
    data() {
        return {
            data: {
                congratulations: {
                    name: "John",
                    saleToday: "48900",
                },
            },
            firebaseData: {},
        };
    },
    created() {
        // data
        const userData = getUserData();
        this.data.congratulations.name =
            userData.username.split(" ")[0] || userData.displayName.split(" ")[0];



        //changing initially
        if (this.$store.state.mainUserCodes) {
            this.$http.get("/recruiter-dashboard/data").then((response) => {
                // console.log(response)
                this.firebaseData = response.data;
            });
        }


        //changing when store value changed
        this.$store.watch(
            (state) => {
                return this.$store.state.mainUserCodes;
            },
            (newVal, oldVal) => {
                this.$http.get("/recruiter-dashboard/data").then((response) => {
                    // console.log(newVal)
                    this.firebaseData = response.data;
                    
                });
            },
            { deep: true }
        );
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
